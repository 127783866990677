import env from '@beam-australia/react-env';
import React, { PropsWithChildren, Suspense } from 'react';
import { SDKProvider } from '@telegram-apps/sdk-react';
import { App as Antd } from 'antd';

import { ExtraProviders } from './features/Utils/lib/Providers';
import { Styles } from './features/Utils/lib/Styles';
import TelegramInit from './features/Utils/lib/TelegramInit';

const App = (props: PropsWithChildren) => {
    const isLocal = env('ENVIRONMENT') === 'local';

    return (
        <Suspense>
            <SDKProvider debug={isLocal} acceptCustomStyles>
                <TelegramInit />
                <ExtraProviders>
                    <Antd>
                        <Styles />
                        {props.children}
                    </Antd>
                </ExtraProviders>
            </SDKProvider>
        </Suspense>
    );
};

export default App;
