import type { RouteObject } from 'react-router';
import { defer } from 'react-router-dom';

import { getProducts } from '../../api/getProducts';

const routes: RouteObject[] = [
    {
        path: 'catalog/:mainId/:connectedId',
        loader: async ({ params }) => {
            return defer({
                main: getProducts(+params.mainId),
                connected: getProducts(+params.connectedId),
            });
        },
        lazy: async () => {
            const { CatalogPage } = await import('../../pages/catalog');
            return {
                Component: CatalogPage,
            };
        },
    },
];

export default routes;
