import dayjs from 'dayjs';
import env from '@beam-australia/react-env';

export const Support = 'mzhn_store_sup_bot';
//export const ShopId = 2;

export const ShopId = () => {
    const isDev = env('ENVIRONMENT') === 'dev';
    const isLocal = env('ENVIRONMENT') === 'local';

    if (isDev || isLocal) {
        return 1;
    } else return 2;
};

export const SellerMail = 'support@mozhno.store';

export const expiredDate = 'September 29, 2024 23:59:59';

export const setExpiredDate = () => {
    //return dayjs().add(1, 'M').format('MMMM D, YYYY HH:MM:ss');
    return expiredDate;
};
