import { defer, RouteObject } from 'react-router-dom';

import CatalogFeature from './features/Catalog';
import ProductFeature from './features/Product';
import CheckoutFeature from './features/Checkout';
import CartFeature from './features/Cart';
import OrdersFeature from './features/Order';
import CommonLayout from './shared/layout/CommonLayout';
import UtilsFeature from './features/Utils';
import { getCategories } from './api/getCategories';

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <CommonLayout />,
        children: [
            {
                path: 'home',
                loader: async () => {
                    return defer({
                        categories: getCategories(),
                    });
                },
                lazy: async () => {
                    const { HomePage } = await import('./pages/home');
                    return {
                        Component: HomePage,
                    };
                },
            },
            {
                path: 'health',
                element: <p>Im healthy</p>,
            },
            ...CatalogFeature.routes,
            ...ProductFeature.routes,
            ...CheckoutFeature.routes,
            ...CartFeature.routes,
            ...OrdersFeature.routes,
            ...UtilsFeature.routes,
        ],
    },
];
